import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import { getAccentColor } from "../../constants/colors"
import { Agent, AgentLanguages, CountryFlags } from "../../types/Agent"

interface CallLanguageProps {
  agent: Agent
  chatEnabled: boolean
}

export default function CallLanguage({
  agent,
  chatEnabled,
}: CallLanguageProps) {
  if (!agent) return null

  const agentLanguage = agent?.language || AgentLanguages.English
  const FlagComponent = CountryFlags[agentLanguage]

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const rightPosition = React.useMemo(() => {
    if (isMobile) {
      return "12.5rem"
    }
    // Position relative to the container width and chat state
    const containerWidth = window.innerWidth * 0.7
    const rightMargin = !chatEnabled
      ? (window.innerWidth - containerWidth) / 2
      : window.innerWidth * 0.3
    return `${rightMargin + 200}px` // 200px = 12.5rem
  }, [isMobile, chatEnabled])

  return (
    <Box
      padding={"6px 12px"}
      position="fixed"
      top={"4.75rem"}
      right={rightPosition}
      color="black"
      sx={{
        backgroundColor: "#EBE9FE",
        backdropFilter: "blur(3px)",
        border: "1px solid #BDB4FE",
      }}
      borderRadius={"50px"}
      display={"flex"}
      alignItems={"center"}
      gap={"0.5rem"}
      zIndex={1}
    >
      <FlagComponent width={22} height={22} title={agentLanguage} />
      <Typography variant="body2" color={getAccentColor()}>
        {agentLanguage.toUpperCase()}
      </Typography>
    </Box>
  )
}
