import {
  ButtonDisplayMode,
  ButtonHierarchy,
  ButtonShape,
  ButtonSize,
  MainButton,
} from "@beydesign/storybook"
import { usePersistentUserChoices } from "@livekit/components-react"
import React from "react"
import { useAppDispatchContext } from "../../app/Context"
import { StateAction } from "../../types/State"

export function MicToggleButton({ getStyle = null }) {
  const dispatch = useAppDispatchContext()

  const { saveAudioInputEnabled, userChoices } = usePersistentUserChoices({
    preventLoad: true,
  })
  const active = userChoices?.audioEnabled || false

  const style = getStyle ? getStyle(active) : {}

  function onClick() {
    dispatch({ type: StateAction.toggleAudio })
    saveAudioInputEnabled(!active)
  }

  return (
    <MainButton
      displayIcon={active ? "Microphone" : "MicrophoneSlash"}
      displayIconMode={ButtonDisplayMode.Only}
      hierarchy={ButtonHierarchy.SecondaryTransparent}
      leadingIcon="Circle"
      shape={ButtonShape.Circle}
      size={ButtonSize.xl}
      destructive={!active}
      text=""
      style={style}
      trailingIcon="Circle"
      onClick={onClick}
    />
  )
}
