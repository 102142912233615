import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore"
import { db } from "../app/App"
import { Agent } from "../types/Agent"
import { Call, ChatMessage } from "../types/Call"
import { Collections } from "../types/Db"
import { User } from "../types/User"

export const verifyAgentExists = async (agentId: string): Promise<boolean> => {
  try {
    const agentRef = doc(db, Collections.agent, agentId)
    const agentSnap = await getDoc(agentRef)
    return agentSnap.exists()
  } catch (error) {
    console.error("Error verifying agent:", error)
    return false
  }
}

export const getAgent = async (agentId: string): Promise<Agent> => {
  const agentRef = doc(db, Collections.agent, agentId)
  const agentSnap = await getDoc(agentRef)
  return agentSnap.data() as Agent
}

export const addCallDb = async (
  user: User,
  agentId: string,
  agent: Agent,
): Promise<string> => {
  const conversationRef = collection(db, Collections.calls)
  const conversation = {
    startedAt: new Date(),
    createdAt: new Date(),
    updatedAt: new Date(),
    creatorId: agent.creatorId,
    userId: user.uid,
    userName: user.name,
    userEmail: user.email,
    userPhotoUrl: user.photoURL || "",
    agentId,
  }
  return (await addDoc(conversationRef, conversation)).id
}

export const updateCallDb = async (call: Call): Promise<void> => {
  const conversationRef = doc(db, Collections.calls, call.id)
  await setDoc(conversationRef, call, { merge: true })
}

export const endCallDb = async (callId: string): Promise<void> => {
  const conversationRef = doc(db, Collections.calls, callId)
  await updateDoc(conversationRef, {
    sendTranscriptToUser: true,
    updatedAt: new Date(),
  })
}

export const addMessageToCallDb = async (
  conversationId: string,
  message: ChatMessage,
): Promise<void> => {
  const conversationRef = collection(
    db,
    Collections.calls,
    conversationId,
    Collections.messages,
  )
  const response = await addDoc(conversationRef, message)
  console.log("response", response)
}

export const updateCallDuration = async (
  callId: string,
  additionalData?: Partial<Call>,
) => {
  try {
    const callRef = doc(db, Collections.calls, callId)
    if (callRef) {
      await updateDoc(callRef, {
        ...additionalData,
      })
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

export const getCall = async (callId: string): Promise<Call | null> => {
  const callRef = doc(db, Collections.calls, callId)
  const callDoc = await getDoc(callRef)
  if (!callDoc.exists()) return null
  return callDoc.data() as Call
}

export const getCallDuration = async (callId: string): Promise<number> => {
  const callRef = doc(db, Collections.calls, callId)
  const callDoc = await getDoc(callRef)
  if (!callDoc.exists()) return null
  return callDoc.data()?.callDuration || 0
}

export const checkCallExists = async (callId: string): Promise<boolean> => {
  const callRef = doc(db, Collections.calls, callId)
  const callDoc = await getDoc(callRef)
  return (
    callDoc.exists() &&
    callDoc.data() !== null &&
    Object.keys(callDoc.data()).length > 0
  )
}
