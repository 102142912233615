import React from "react"

import { Stack } from "@mui/material"
import { MediaSelect } from "./MediaSelect"
import { VideoOrPlaceholder } from "./VideoOrPlaceholder"

interface MediaSetupProps {
  setPermissionsGranted: (granted: boolean) => void
  disableButton: boolean
}

export function MediaSetup({
  setPermissionsGranted,
  disableButton,
}: MediaSetupProps) {
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        width: "100%",
        maxWidth: "100%",
        position: "relative",
      }}
    >
      <VideoOrPlaceholder
        setPermissionsGranted={setPermissionsGranted}
        disableButton={disableButton}
      />
      <MediaSelect />
    </Stack>
  )
}
