import { useAppContext } from "../app/Context"

export enum Colors {
  Primary = "#40069E",
  Accent = "#4019B6",
  Background = "#DCD9E2",
  Blue = "#27115F",
  Black = "#000000",
  White = "#FFFFFF",
  Green = "#C8E846",
  Purple = "#220738",
  Yellow = "#DCF76D",
  Grey = "#7C768A",
  BrandFontColor = "#2D1342",
}

export enum FontFamilies {
  Figtree = "figtree, sans-serif",
}

export const getFontFamily = () => {
  const agent = useAppContext()?.agent
  return agent?.custom_branding
    ? agent?.custom_font_family
    : FontFamilies.Figtree
}

export const isCustomBrandingEnabled = () => {
  const agent = useAppContext()?.agent
  if (!agent) return false
  if (agent?.custom_branding === null) return false
  return agent?.custom_branding
}

export const isBeyBrandingEnabled = () => {
  const agent = useAppContext()?.agent
  if (!agent) return false
  if (agent?.custom_branding == null) return false
  if (agent?.custom_allow_bey_branding == null) return true
  return agent?.custom_allow_bey_branding
}

export const getPrimaryColor = () => {
  const agent = useAppContext()?.agent
  return agent?.custom_branding ? agent?.custom_brand_color : Colors.Primary
}

export const getAccentColor = () => {
  return Colors.Accent
}

export const getFontColor = (fontColor?: string) => {
  const agent = useAppContext()?.agent
  return agent?.custom_branding
    ? agent?.custom_font_color
      ? agent?.custom_font_color
      : Colors.BrandFontColor
    : Colors.Primary
}

export const getBackgroundSecondaryColor = () => {
  const agent = useAppContext()?.agent
  return agent?.custom_branding ? agent?.custom_brand_color : Colors.Yellow
}
