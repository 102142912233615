import { Logo, LogoVariant } from "@beydesign/storybook"
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import * as Sentry from "@sentry/react"
import { getAuth, signInAnonymously } from "firebase/auth"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { AccountMobile } from "../../components/accountMobile"
import AgentNameHeader from "../../components/agentNameHeader"
import Background from "../../components/background"
import { Header } from "../../components/header"
import { Loader } from "../../components/loader"
import { SnackbarWithText } from "../../components/snackbar"
import {
  Colors,
  isBeyBrandingEnabled,
  isCustomBrandingEnabled,
} from "../../constants/colors"
import { logAnalyticsEvent } from "../../external/analytics"
import { setSessionCookie } from "../../external/auth"
import { getAgent, verifyAgentExists } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import { StateAction } from "../../types/State"
import { SnackbarType } from "../../types/UI"
import { getAvailableCredits } from "../../utils/func"
import {
  getAgentUsage,
  getCapacity,
  getDefaultAgentId,
} from "../../utils/general"
import { useAppContext, useAppDispatchContext } from "../Context"
import AgentNotFound from "./AgentNotFound"
import { MediaSetup } from "./MediaSetup"
import { UserInput } from "./UserInput"
const sendAnalyticsEvent = () => {
  logAnalyticsEvent(AnalyticsEvents.AgentVisited)
}

export function PreScreenCustomBranding() {
  const [permissionsGranted, setPermissionsGranted] = useState(false)
  const [capacity, setCapacity] = useState<boolean>(false)
  const [overUsage, setOverUsage] = useState<boolean>(false)
  const [agentError, setAgentError] = useState<string>("")
  const [agentExists, setAgentExists] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const customBrandingEnabled = isCustomBrandingEnabled()

  const auth = getAuth()

  const { state } = useLocation()
  const location = useLocation()
  const { user, stream } = useAppContext()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatchContext()
  const beyBrandingEnabled = isBeyBrandingEnabled()

  useEffect(() => {
    setPermissionsGranted(false)
    if (stream) {
      dispatch({ type: StateAction.setStream, payload: null })
    }
  }, [location.pathname, location.search])

  useEffect(() => {
    if (state && user) {
      navigate(`/${state}`)
    }
  }, [state, user])

  useEffect(() => {
    const initialize = async () => {
      try {
        const result = await signInAnonymously(auth)
        const token = await result.user.getIdToken(true)
        setSessionCookie(token)

        if (!result.user) {
          setAgentError("Failed to authenticate user")
          return
        }

        const agentId = id || getDefaultAgentId

        if (agentId === getDefaultAgentId) {
          setAgentExists(true)
          dispatch({ type: StateAction.setAgentId, payload: agentId })
          dispatch({ type: StateAction.setAgentUsage, payload: null })
        } else {
          const exists = await verifyAgentExists(agentId)
          setAgentExists(exists)
          if (!exists) {
            setAgentError(`Agent with ID ${agentId} does not exist`)
            return
          }

          const [usage, agent] = await Promise.all([
            getAgentUsage(agentId),
            getAgent(agentId),
          ])

          dispatch({ type: StateAction.setAgent, payload: agent })

          if (usage.pay_as_you_go_enabled) {
            dispatch({
              type: StateAction.setAgentUsage,
              payload: {
                pay_as_you_go_enabled: usage.pay_as_you_go_enabled,
                current_usage: usage.current_usage,
                usage_limit: null,
                subscription_plan: usage.subscription_plan,
              },
            })
          } else {
            dispatch({ type: StateAction.setAgentUsage, payload: usage })
            const remainingCredits = getAvailableCredits(usage)
            setOverUsage(remainingCredits <= 0)
          }
        }
      } catch (error) {
        console.error("Error during initialization:", error)
        Sentry.captureException(error)
        setAgentError("Error initializing the application")
      } finally {
        setIsLoading(false)
      }
    }
    initialize()
  }, [])

  useEffect(() => {
    sendAnalyticsEvent()
  }, [sendAnalyticsEvent])

  useEffect(() => {
    const req = async () => {
      try {
        if (user) {
          const res = await getCapacity()
          setCapacity(res)
        }
      } catch (error) {
        console.error("error fetching capacity", error)
      }
    }
    req()
  }, [user])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{
        position: "relative",
        minHeight: "100vh",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Header />
      <SnackbarWithText
        open={agentError !== ""}
        severity={SnackbarType.error}
        text={agentError}
        handleClose={() => setAgentError("")}
      />
      <Stack
        direction={"column"}
        spacing={2}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
          px: { xs: 1, sm: 2, md: 2, lg: 3, xl: 4 },
          overflow: "hidden",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Grid
          container
          spacing={isMobile ? 1 : 3}
          justifyContent="center"
          alignItems="center"
          sx={{
            p: { xs: 1, sm: 2, md: 2, lg: 3 },
            width: "100%",
            m: 0,
            maxWidth: "1440px",
            position: "relative",
            zIndex: 1,
          }}
        >
          <Grid
            item
            md={1}
            xl={2}
            display={{ xs: "none", md: "block" }}
            sx={{ maxWidth: { md: "4%", lg: "8.33%" } }}
          />
          <Grid
            item
            xs={12}
            md={6}
            xl={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              maxWidth: { xs: "100%", sm: "600px", md: "58%", lg: "50%" },
              mx: "auto",
              px: { xs: 0, sm: 1, md: 1, lg: 2 },
              gap: 1,
              position: "relative",
              zIndex: 1,
            }}
          >
            <AgentNameHeader />
            <MediaSetup
              setPermissionsGranted={setPermissionsGranted}
              disableButton={capacity || overUsage || !agentExists}
            />
          </Grid>
          <Grid
            xs={12}
            item
            md={4}
            xl={3}
            sx={{
              width: "100%",
              maxWidth: {
                xs: "100%",
                sm: "600px",
                md: "38%",
                lg: "33.333%",
              },
              mx: "auto",
              px: { xs: 0, sm: 1, md: 1, lg: 2 },
              mt: { xs: 2, md: 0 },
              position: "relative",
              zIndex: 1,
            }}
          >
            {agentExists ? (
              <UserInput
                permissionsGranted={permissionsGranted}
                capacity={capacity}
                overUsage={overUsage}
              />
            ) : (
              <AgentNotFound />
            )}
          </Grid>
          <Grid
            item
            md={1}
            xl={2}
            display={{ xs: "none", md: "block" }}
            sx={{ maxWidth: { md: "4%", lg: "8.33%" } }}
          />
        </Grid>
        {isMobile ? <AccountMobile /> : null}
      </Stack>
      <Box
        alignItems={"center"}
        display={"flex"}
        position={"fixed"}
        gap={1}
        bottom={"1rem"}
        left={"50%"}
        sx={{ transform: "translateX(-50%)", zIndex: 2 }}
      >
        {beyBrandingEnabled && (
          <>
            <Typography color={Colors.Grey} variant="body2">
              powered by
            </Typography>
            <Logo
              color={customBrandingEnabled ? Colors.Grey : undefined}
              variant={LogoVariant.Horizontal}
            />
          </>
        )}
      </Box>
      <Background />
    </Stack>
  )
}
