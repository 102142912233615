import {
  GridLayout,
  ChatMessage as LiveKitChatMessage,
  ParticipantTile,
  TrackReferenceOrPlaceholder,
  WidgetState,
} from "@livekit/components-react"
import { Box, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { TrackPublication } from "livekit-client"
import React, { Fragment } from "react"
import { Colors } from "../../constants/colors"
import { Agent } from "../../types/Agent"
import AgentNameHeader from "../agentNameHeader"
import { UserVideoLiveKit } from "../UserVideo/UserVideoLiveKit"
import { AppLogo } from "./AppLogo"
import CallLanguage from "./CallLanguage"
import CustomCallTimer from "./CustomCallTimer"
import { CustomChatBox } from "./CustomChatBox"
import { CustomControlsBar } from "./CustomControlsBar"

type LayoutProps = {
  agent: Agent
  tracks: TrackReferenceOrPlaceholder[]
  showChat: boolean
  audioState: boolean
  videoState: boolean
  localCameraTrack: TrackPublication
  handleDisconnect: () => void
  toggleChat: () => void
  duration: number
  maxDuration: number
  closeChat: () => void
  openChat: () => void
  chatMessages: LiveKitChatMessage[]
  isSending: boolean
  send: (message: string) => Promise<LiveKitChatMessage>
  update: (
    message: string,
    originalMessageOrId: string | LiveKitChatMessage,
  ) => Promise<{
    readonly message: string
    readonly editTimestamp: number
    readonly id: string
    readonly timestamp: number
  }>
  SettingsComponent: React.ComponentType
  widgetState: WidgetState
}

export const WebLayout = React.memo(function WebLayout({
  tracks,
  showChat,
  localCameraTrack,
  handleDisconnect,
  toggleChat,
  duration,
  maxDuration,
  closeChat,
  openChat,
  chatMessages,
  isSending,
  send,
  update,
  SettingsComponent,
  widgetState,
  audioState,
  videoState,
  agent,
}: LayoutProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Fragment>
      <div
        className="lk-video-conference-inner"
        style={{
          width: isMobile ? "100%" : "70%",
          marginRight: showChat ? "auto" : "unset",
          margin: !showChat && !isMobile ? "0 auto" : undefined,
        }}
      >
        <AgentNameHeader sx={{ mb: 1, bgcolor: "#FFFFFF10" }} />
        <GridLayout
          tracks={tracks.filter(
            (track) =>
              !track.participant.isLocal &&
              !(
                track.participant.isAgent &&
                track.participant.attributes &&
                track.participant.attributes["lk.agent.state"]
              ),
          )}
          style={{ borderRadius: "12px", overflow: "hidden" }}
        >
          <ParticipantTile />
        </GridLayout>
        <UserVideoLiveKit chatEnabled={showChat} track={localCameraTrack} />
        <CustomControlsBar
          showChat={showChat}
          disconnect={handleDisconnect}
          toggleChat={toggleChat}
          audioState={audioState}
          videoState={videoState}
        />
        <CustomCallTimer
          chatEnabled={showChat}
          duration={duration}
          maxDuration={maxDuration}
        />
        <CallLanguage agent={agent} chatEnabled={showChat} />
        <AppLogo chatEnabled={showChat} />
      </div>
      <CustomChatBox
        showChat={showChat}
        closeChat={closeChat}
        openChat={openChat}
        chatMessages={chatMessages}
        isSending={isSending}
        send={send}
        update={update}
      />
      {SettingsComponent && (
        <div
          className="lk-settings-menu-modal"
          style={{ display: widgetState.showSettings ? "block" : "none" }}
        >
          <SettingsComponent />
        </div>
      )}
    </Fragment>
  )
})

export const MobileLayout = React.memo(function MobileLayout({
  tracks,
  showChat,
  localCameraTrack,
  handleDisconnect,
  toggleChat,
  duration,
  maxDuration,
  closeChat,
  openChat,
  chatMessages,
  isSending,
  send,
  update,
  SettingsComponent,
  widgetState,
  audioState,
  videoState,
  agent,
}: LayoutProps) {
  return (
    <Box display={"flex"} flexDirection={"column"} height={"100%"}>
      <Box flex={1} position={"relative"}>
        <div
          className="lk-video-conference-inner"
          style={{ height: "90%", width: "100%" }}
        >
          <AgentNameHeader sx={{ mb: 1, bgcolor: `${Colors.White}10` }} />
          <GridLayout
            tracks={tracks.filter(
              (track) =>
                !track.participant.isLocal &&
                !(
                  track.participant.isAgent &&
                  track.participant.attributes &&
                  track.participant.attributes["lk.agent.state"]
                ),
            )}
            style={{ height: "100%", width: "100%" }}
            autoFocus
          >
            <ParticipantTile />
          </GridLayout>
          <UserVideoLiveKit chatEnabled={showChat} track={localCameraTrack} />
          <CustomControlsBar
            showChat={showChat}
            disconnect={handleDisconnect}
            toggleChat={toggleChat}
            audioState={audioState}
            videoState={videoState}
          />
          <CustomCallTimer
            chatEnabled={showChat}
            duration={duration}
            maxDuration={maxDuration}
          />
          <CallLanguage agent={agent} chatEnabled={showChat} />
          <AppLogo />
        </div>
      </Box>
      <CustomChatBox
        showChat={showChat}
        closeChat={closeChat}
        openChat={openChat}
        chatMessages={chatMessages}
        isSending={isSending}
        send={send}
        update={update}
      />
      {SettingsComponent && (
        <div
          className="lk-settings-menu-modal"
          style={{ display: widgetState.showSettings ? "block" : "none" }}
        >
          <SettingsComponent />
        </div>
      )}
    </Box>
  )
})
