export enum Method {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum ReturnTypes {
  json = "json",
  blob = "blob",
}

export enum Endpoints {
  offer = "offer",
  call = "calls",
  video = "video",
  getToken = "getToken",
  deleteRoom = "deleteRoom",
  agent = "agent",
  capacity = "capacity",
  creator = "creator",
  feedback = "feedback",
  createCheckoutSession = "create-checkout-session",
}

export enum SecondPathParams {
  usage = "usage",
  verifyToken = "verify-token",
  payAsYouGo = "pay-as-you-go",
}
