import { Box, Typography } from "@mui/material"
import React from "react"
import AgentNotFoundImage from "../../assets/Agent404.svg"
import { Colors, getFontColor } from "../../constants/colors"

export default function AgentNotFound() {
  return (
    <Box position="relative">
      <Box
        position="absolute"
        top={-150}
        right={-150}
        width="100%"
        height="100%"
      >
        <img src={AgentNotFoundImage} alt="Agent not found" />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography
          variant="body1"
          fontWeight={600}
          color={getFontColor(Colors.Blue)}
          textAlign="left"
          fontSize={22}
        >
          404: Agent not found.
        </Typography>
        <Typography
          variant="body2"
          color={getFontColor(Colors.Blue)}
          textAlign="left"
          fontSize={12}
        >
          Please check that the url is correct and try again.
        </Typography>
      </Box>
    </Box>
  )
}
