import { Box, capitalize, SxProps, Theme, Typography } from "@mui/material"
import React from "react"
import { useAppContext } from "../app/Context"
import { Colors } from "../constants/colors"

interface AgentNameHeaderProps {
  sx?: SxProps<Theme>
}

export default function AgentNameHeader({ sx }: AgentNameHeaderProps) {
  const { agent, user } = useAppContext() || {}

  if (!user || !agent) return null

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={`${Colors.White}66`}
      p={1}
      borderRadius={1}
      flexGrow={1}
      sx={sx}
    >
      <Typography variant="body2" fontWeight={"bold"}>
        Chat with {capitalize(agent?.name || "Agent")}
      </Typography>
    </Box>
  )
}
