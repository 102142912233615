import { Logo as LogoComponent, LogoVariant } from "@beydesign/storybook"
import { Box } from "@mui/material"
import React from "react"
import { useAppContext } from "../app/Context"
import { Colors, isCustomBrandingEnabled } from "../constants/colors"
export function Logo() {
  const context = useAppContext()
  const agent = context.agent

  const redirectToHome = () => {
    if (agent?.custom_branding) {
      window.open(agent?.custom_brand_website || "/", "_blank")
    } else {
      window.location.href = "/"
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={redirectToHome}
    >
      <LogoComponent
        svgUrl={agent?.custom_branding ? agent?.custom_logo_url : null}
        variant={LogoVariant.Horizontal}
        color={!isCustomBrandingEnabled() ? Colors.Primary : undefined}
      />
    </Box>
  )
}
