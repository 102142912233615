import { Player } from "@lottiefiles/react-lottie-player"
import { Box, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React from "react"
import CallLoadingAnimation from "../../assets/call-loading.json"
import {
  Colors,
  getPrimaryColor,
  isCustomBrandingEnabled,
} from "../../constants/colors"
import AgentNameHeader from "../agentNameHeader"
import { AppLogo } from "./AppLogo"

export default function CallLoading() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Box
      height={"100vh"}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      position={"relative"}
      sx={{
        padding: "25px",
        backgroundColor: isCustomBrandingEnabled()
          ? getPrimaryColor()
          : Colors.Blue,
      }}
    >
      {isMobile ? (
        // Mobile Layout
        <Box
          display={"flex"}
          flexDirection={"column"}
          height={"100%"}
          width={"100%"}
        >
          <Box flex={1} position={"relative"}>
            <div
              className="lk-video-conference-inner"
              style={{ height: "90%", width: "100%" }}
            >
              <AgentNameHeader
                sx={{
                  mb: 1,
                  bgcolor: "#FFFFFF10",
                  flexGrow: 0,
                  height: "40px",
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "12px",
                  background: isCustomBrandingEnabled()
                    ? "white"
                    : "linear-gradient(to right, #EAFBA4, #F5F8DD,#EBD7EF,#DDBFF6)",
                  overflow: "hidden",
                }}
              >
                <Player
                  autoplay
                  loop
                  src={CallLoadingAnimation}
                  style={{ width: "100%", maxWidth: "300px" }}
                />
                <Typography
                  color={Colors.Purple}
                  variant="body2"
                  fontWeight={500}
                  sx={{ mt: 2, px: 2, textAlign: "center" }}
                >
                  It may take up to 20 seconds to join the call due to high
                  traffic.
                </Typography>
              </Box>
              <AppLogo chatEnabled={false} isLoading={true} />
            </div>
          </Box>
        </Box>
      ) : (
        // Desktop Layout
        <div
          className="lk-video-conference-inner"
          style={{
            width: "70%",
            marginRight: "unset",
            margin: "0px auto",
            height: "calc(100vh - 50px)",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <AgentNameHeader
            sx={{
              mb: 1,
              bgcolor: "#FFFFFF10",
              flexGrow: 0,
              height: "40px",
            }}
          />
          <Box
            sx={{
              width: "100%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "12px",
              background: isCustomBrandingEnabled()
                ? "white"
                : "linear-gradient(to right, #EAFBA4, #F5F8DD,#EBD7EF,#DDBFF6)",
              overflow: "hidden",
            }}
          >
            <Player
              autoplay
              loop
              src={CallLoadingAnimation}
              style={{ width: "100%", maxWidth: "400px" }}
            />
            <Typography
              color={Colors.Purple}
              variant="body2"
              fontWeight={500}
              sx={{ mt: 2 }}
            >
              It may take up to 20 seconds to join the call due to high traffic.
            </Typography>
          </Box>
          <AppLogo chatEnabled={false} isLoading={true} />
        </div>
      )}
    </Box>
  )
}
