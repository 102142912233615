import React, { useEffect, useState } from "react"

import { usePersistentUserChoices } from "@livekit/components-react"
import { Stack, useMediaQuery, useTheme } from "@mui/material"
import { Microphone, VideoCamera } from "@phosphor-icons/react"
import * as Sentry from "@sentry/react"
import { Colors } from "../../constants/colors"
import { StateAction } from "../../types/State"
import { isLocalDev } from "../../utils/general"
import { replaceStreamTrack } from "../../utils/media"
import { useAppContext, useAppDispatchContext } from "../Context"
import { MediaSelectForm } from "./MediaSelectForm"

interface MediaSelectProps {}

export function MediaSelect({}: MediaSelectProps) {
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([])
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([])

  const context = useAppContext()
  const stream = context.stream
  const dispatch = useAppDispatchContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { saveAudioInputDeviceId, saveVideoInputDeviceId, userChoices } =
    usePersistentUserChoices({
      preventLoad: true,
    })

  const microphone = context.audioDevice || ""
  const camera = context.videoDevice || ""

  const isDefaultDevice = (device: MediaDeviceInfo) => {
    return device.label.includes("Default") || device.label.includes("default")
  }

  useEffect(() => {
    const requestMedia = async () => {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const foundMicrophones: MediaDeviceInfo[] = []
        const foundCameras: MediaDeviceInfo[] = []
        devices.forEach((device) => {
          if (device.kind === "audioinput") {
            if (isDefaultDevice(device)) {
              foundMicrophones.unshift(device)
            } else {
              foundMicrophones.push(device)
            }
          } else if (device.kind === "videoinput") {
            if (isDefaultDevice(device)) {
              foundCameras.unshift(device)
            } else {
              foundCameras.push(device)
            }
          }
        })

        setMicrophones(foundMicrophones)
        setCameras(foundCameras)
        if (foundMicrophones.length > 0) {
          saveAudioInputDeviceId(foundMicrophones[0].deviceId)
          dispatch({
            type: StateAction.setAudioDevice,
            payload: foundMicrophones[0].deviceId,
          })
        }
        if (foundCameras.length > 0) {
          saveVideoInputDeviceId(foundCameras[0].deviceId)
          dispatch({
            type: StateAction.setVideoDevice,
            payload: foundCameras[0].deviceId,
          })
        }
        if (!isLocalDev) {
          // Log available devices to Sentry
          const deviceStates = {
            availableMicrophones: foundMicrophones.map((device) => ({
              deviceId: device.deviceId,
              label: device.label,
              kind: device.kind,
              isDefault: isDefaultDevice(device),
              groupId: device.groupId,
            })),
            availableCameras: foundCameras.map((device) => ({
              deviceId: device.deviceId,
              label: device.label,
              kind: device.kind,
              isDefault: isDefaultDevice(device),
              groupId: device.groupId,
            })),
            selectedDevices: {
              microphone: {
                deviceId: microphone,
                info: foundMicrophones.find((m) => m.deviceId === microphone),
                isDefault: foundMicrophones.find(
                  (m) => m.deviceId === microphone,
                )
                  ? isDefaultDevice(
                      foundMicrophones.find((m) => m.deviceId === microphone),
                    )
                  : false,
              },
              camera: {
                deviceId: camera,
                info: foundCameras.find((c) => c.deviceId === camera),
                isDefault: foundCameras.find((c) => c.deviceId === camera)
                  ? isDefaultDevice(
                      foundCameras.find((c) => c.deviceId === camera),
                    )
                  : false,
              },
            },
            counts: {
              totalMicrophones: foundMicrophones.length,
              totalCameras: foundCameras.length,
              defaultMicrophones: foundMicrophones.filter((m) =>
                isDefaultDevice(m),
              ).length,
              defaultCameras: foundCameras.filter((c) => isDefaultDevice(c))
                .length,
            },
          }
          Sentry.addBreadcrumb({
            message: "Available Media Devices Details",
            level: "info",
            data: deviceStates,
          })
        }
      })
    }
    requestMedia()
  }, [stream])

  async function onMicrophoneChange(e) {
    const deviceId = e.target.value
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: { exact: deviceId } },
    })
    replaceStreamTrack(stream, audioStream.getAudioTracks()[0], "audio")
    dispatch({ type: StateAction.setAudioDevice, payload: deviceId })
    console.log("saving audio device id", deviceId)
    saveAudioInputDeviceId(deviceId)
  }

  async function onCameraChange(e) {
    const deviceId = e.target.value
    const videoStream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: { exact: deviceId } },
    })
    replaceStreamTrack(stream, videoStream.getVideoTracks()[0], "video")
    dispatch({ type: StateAction.setVideoDevice, payload: deviceId })
    console.log("saving video device id", deviceId)
    saveVideoInputDeviceId(deviceId)
  }

  return (
    <>
      {stream != null && stream.getTracks != null ? (
        <Stack
          direction={"row"}
          spacing={2}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: "600px",
            mx: "auto",
          }}
        >
          {microphones.length > 0 && (
            <MediaSelectForm
              name="Microphone"
              icon={<Microphone size={20} color={Colors.Black} />}
              options={microphones}
              currentOption={microphone}
              onChange={onMicrophoneChange}
            ></MediaSelectForm>
          )}
          {cameras.length > 0 && (
            <MediaSelectForm
              name="Camera"
              icon={<VideoCamera size={20} color={Colors.Black} />}
              options={cameras}
              currentOption={camera}
              onChange={onCameraChange}
            ></MediaSelectForm>
          )}
        </Stack>
      ) : null}
    </>
  )
}
