import React from "react"

import { Avatar, AvatarSize, AvatarStyle } from "@beydesign/storybook"
import {
  Box,
  Button,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useAppContext, useAppDispatchContext } from "../app/Context"
import { getAccentColor } from "../constants/colors"
import { signUserOut } from "../external/auth"
import { StateAction } from "../types/State"
import { isAnonymous } from "../utils/func"
import { Logo } from "./logo"

export function Header() {
  const context = useAppContext()
  const user = context.user

  const isUserAnonymous = isAnonymous()
  const navigate = useNavigate()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const dispatch = useAppDispatchContext()

  const signOut = async () => {
    await signUserOut()
    dispatch({ type: StateAction.setUser, payload: null })
    navigate("/")
  }

  return (
    <>
      {isMobile ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ height: "10vh" }}
        >
          <Logo />
        </Stack>
      ) : (
        <Toolbar
          sx={{
            height: "10vh",
            justifyContent: "space-between",
            borderBottom: "1px solid #E7E6ED",
          }}
        >
          <Logo />
          {user != null && !isUserAnonymous && (
            <Box sx={{ dislay: "flex", alignItems: "center", flexGrow: 0 }}>
              <Stack direction="row" spacing={2}>
                <Stack alignItems="flex-end">
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    {user?.email}
                  </Typography>
                  <Button
                    variant="text"
                    onClick={signOut}
                    sx={{ textTransform: "none", color: "black" }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      color={getAccentColor()}
                    >
                      Switch account
                    </Typography>
                  </Button>
                </Stack>
                <Avatar
                  size={AvatarSize.lg}
                  image={user?.photoURL}
                  text={user?.name}
                  avatarStyle={
                    user.name
                      ? user?.photoURL
                        ? AvatarStyle.Picture
                        : AvatarStyle.Letters
                      : AvatarStyle.Placeholder
                  }
                />
              </Stack>
            </Box>
          )}
        </Toolbar>
      )}
    </>
  )
}
