import { Box, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import {
  getBackgroundSecondaryColor,
  getPrimaryColor,
} from "../constants/colors"

const Svg2 = ({ isMobile }: { isMobile: boolean }) => {
  const size = isMobile ? 0.7 : 1
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1031"
      height="1080"
      viewBox="0 0 1031 1080"
      fill="none"
      style={{
        fill: getBackgroundSecondaryColor(),
        filter: `blur(${isMobile ? "70px" : "100px"})`,
        width: `${630.586 * size}px`,
        height: `${820.5 * size}px`,
        flexShrink: 0,
        maxWidth: "100%",
        maxHeight: "100%",
      }}
    >
      <g filter="url(#filter0_f_1375_6184)">
        <path
          d="M830.586 398.5C830.586 571.089 504.676 906.5 332.087 906.5C159.498 906.5 205.586 571.089 205.586 398.5C205.586 225.911 345.497 86 518.086 86C690.675 86 558.586 486 830.586 398.5Z"
          fill={getBackgroundSecondaryColor()}
          fillOpacity="0.4"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1375_6184"
          x="0"
          y="-114"
          width="1030.59"
          height="1220.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="100"
            result="effect1_foregroundBlur_1375_6184"
          />
        </filter>
      </defs>
    </svg>
  )
}

const Svg1 = ({ isMobile }: { isMobile: boolean }) => {
  const size = isMobile ? 0.7 : 1
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1070"
      height="1037"
      viewBox="0 0 1070 1037"
      fill={getPrimaryColor()}
      style={{
        fill: getPrimaryColor(),
        filter: `blur(${isMobile ? "70px" : "100px"})`,
        width: `${669.813 * size}px`,
        height: `${778 * size}px`,
        flexShrink: 0,
        maxWidth: "100%",
        maxHeight: "100%",
      }}
    >
      <g filter="url(#filter0_f_1375_6183)">
        <path
          d="M869.813 665.5C869.813 838.089 729.902 978 557.314 978C354.314 572.5 4.81438 918.5 337.814 585.5C472.315 407 285.725 200 458.314 200C630.903 200 869.813 492.911 869.813 665.5Z"
          fill={getPrimaryColor()}
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1375_6183"
          x="0"
          y="0"
          width="1069.81"
          height="1178"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="100"
            result="effect1_foregroundBlur_1375_6183"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default function Background() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      zIndex={-1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      sx={{
        opacity: { xs: 0.8, sm: 1 },
        pointerEvents: "none",
      }}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          transform: { xs: "scale(1.1)", sm: "scale(1)" },
          position: "relative",
          pointerEvents: "none",
        }}
      >
        <Svg1 isMobile={isMobile} />
        <Svg2 isMobile={isMobile} />
      </Box>
    </Box>
  )
}
