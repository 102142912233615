import { ReceivedChatMessage } from "@livekit/components-react"
import { Avatar, Box, Typography } from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import { Colors } from "../../constants/colors"
import { decodeMessage } from "../../utils/func"

interface ChatBoxProps {
  item: ReceivedChatMessage
}

export function ChatBox({ item }: ChatBoxProps) {
  const isMessageFromAgent = item?.from.isAgent
  const name = isMessageFromAgent ? "Agent" : item.from.name

  return (
    <Box display={"flex"} flexDirection={"row"} gap={1}>
      <Box display={"flex"} alignItems={"flex-start"}>
        <Avatar sx={{ width: { xs: 28, sm: 40 }, height: { xs: 28, sm: 40 } }}>
          {item.from.name.charAt(0)}
        </Avatar>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={1}
        >
          <Typography
            fontSize={{ xs: "0.75rem", sm: "0.875rem" }}
            variant="body1"
            color={Colors.Purple}
            fontWeight={600}
          >
            {name}
          </Typography>
          <Typography
            variant="body1"
            fontSize={{ xs: "0.625rem", sm: "0.75rem" }}
            color="gray"
          >
            {dayjs(item.timestamp).format("h:mm A")}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: isMessageFromAgent ? "#F5EFFF" : "#F5F5F7",
            padding: "0.5rem",
            borderRadius: "1rem",
            borderTopLeftRadius: "0.25rem",
            borderTopRightRadius: "1rem",
            width: "fit-content",
            marginRight: "0.5rem",
            position: "relative",
          }}
        >
          <Typography
            variant="body1"
            color={Colors.Purple}
            fontSize={{ xs: "0.8rem", sm: "1rem" }}
            sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
          >
            {decodeMessage(item?.message) || "No message"}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
