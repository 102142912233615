import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"

export function TitleTextButton({
  text,
  title,
  subtitle,
  button,
}: {
  text: JSX.Element
  title: string
  subtitle?: string
  button: JSX.Element
}) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isLaptop = useMediaQuery("(min-width:960px) and (max-width:1279px)")
  const alignment = isMobile ? "center" : "flex-start"
  const textAlign = isMobile ? "center" : "left"

  return (
    <Stack
      direction="column"
      alignItems={alignment}
      textAlign={textAlign}
      sx={{
        width: "100%",
        maxWidth: isLaptop ? "90%" : "100%",
        mx: "auto",
      }}
    >
      <Typography
        variant="body1"
        textAlign={textAlign}
        sx={{
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "110%",
          width: "100%",
        }}
        mb={3}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="body1"
          textAlign={textAlign}
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "130%",
            width: "100%",
          }}
          mb={2}
        >
          {subtitle}
        </Typography>
      )}
      <Box
        flexDirection={"column"}
        display={"flex"}
        justifyContent={"start"}
        alignItems={alignment}
        width={"100%"}
        sx={{
          "& > *": {
            // This targets all direct children of the Box
            width: "100%", // Make all children take full width
          },
        }}
      >
        {button}
      </Box>
      <Box
        sx={{
          mt: 2,
          fontSize: "12px",
          textAlign: textAlign,
          width: "100%",
        }}
      >
        {text}
      </Box>
    </Stack>
  )
}
