export enum ChatMessageSender {
  user = "user",
  ai = "ai",
}

export interface ChatMessage {
  message: string
  sentAt: Date
  sender: ChatMessageSender
  hidden?: boolean
}

export interface Call {
  id: string
  agentId?: string
  chatMessages: ChatMessage[]
  leftAt?: Date | string
  startedAt: Date | string
  endedAt?: Date
  rating?: number
  feedback?: string
  creatorId?: string
  callDuration?: number
  sendTranscriptToUser?: boolean | false
}

export enum ChatMessageInputType {
  text = "text",
  voice = "voice",
}

export interface RoomLoader {
  matchedElement: Call
}
