import {
  ButtonHierarchy,
  ButtonSize,
  MainButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from "@beydesign/storybook"
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material"

import React, { Fragment, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import AgentNotFoundImage from "../../assets/Agent404.svg"
import Background from "../../components/background"
import Feedback from "../../components/Feedback/Feedback"
import FeedbackSubmitted from "../../components/Feedback/FeedbackSubmitted"
import { Header } from "../../components/header"
import { SnackbarWithText } from "../../components/snackbar"
import { logAnalyticsEvent } from "../../external/analytics"
import { endCallDb, getCall } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import { Call } from "../../types/Call"
import { StateAction } from "../../types/State"
import { SnackbarType } from "../../types/UI"
import { getAgent, getAgentUsage, getDefaultAgentId } from "../../utils/general"
import { useAppContext, useAppDispatchContext } from "../Context"

export function PostScreen() {
  const { id } = useParams()
  const context = useAppContext()
  const dispatch = useAppDispatchContext()
  const email = context.user.email
  const agent = context.agent
  const [call, setCall] = useState<Call | null>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))

  const navigate = useNavigate()
  const { state } = useLocation()
  const error = state?.error || false

  const [summaryOpen, setSummaryOpen] = useState(false)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [headline, setHeadline] = useState("You left the meeting")
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)

  const hasCustomButton = useMemo(() => {
    return (
      agent?.custom_branding &&
      agent?.custom_brand_button_text &&
      agent?.custom_brand_button_url
    )
  }, [agent])

  useEffect(() => {
    const fetchCall = async () => {
      const call = await getCall(id)
      setCall(call)
    }
    fetchCall()
  }, [id])

  useEffect(() => {
    const fetchCallAndAgent = async () => {
      if (error) return

      try {
        if (call && call.agentId && call.agentId !== getDefaultAgentId) {
          const [agent, agentUsage] = await Promise.all([
            getAgent(call.agentId),
            getAgentUsage(call.agentId),
          ])
          dispatch({ type: StateAction.setAgent, payload: agent })
          if (!agentUsage.pay_as_you_go_enabled) {
            dispatch({ type: StateAction.setAgentUsage, payload: agentUsage })
          } else {
            dispatch({
              type: StateAction.setAgentUsage,
              payload: {
                pay_as_you_go_enabled: true,
                current_usage: agentUsage.current_usage,
                usage_limit: null,
                subscription_plan: agentUsage.subscription_plan,
              },
            })
          }
        } else if (call && call.agentId === getDefaultAgentId) {
          dispatch({
            type: StateAction.setAgentId,
            payload: getDefaultAgentId,
          })
        }
      } catch (error) {
        console.error("Error fetching call and agent:", error)
      }
    }
    fetchCallAndAgent()
  }, [id, dispatch])

  const handleCustomButtonClick = () => {
    window.open(agent?.custom_brand_button_url, "_self")
    setButtonsDisabled(true)
    setHeadline("Your meeting has ended")
  }

  const handleEndCallAndSummary = async () => {
    if (!agent?.custom_branding) {
      await endCallDb(id)
      setSummaryOpen(true)
    }
    setButtonsDisabled(true)
    setHeadline("Your meeting has ended")
    logAnalyticsEvent(AnalyticsEvents.SummaryRequested)
  }

  const rejoin = async () => {
    logAnalyticsEvent(AnalyticsEvents.Rejoined)
    if (!call) {
      console.error("Cannot rejoin: call is null")
      return
    }
    const path = agent?.custom_branding
      ? `/agent/${call.agentId}`
      : `/${call.agentId}`
    navigate(path)
  }

  const handleClose = () => {
    setFeedbackOpen(false)
    setSummaryOpen(false)
  }

  const emailAddress = useMemo(() => {
    if (agent?.custom_branding) {
      return agent?.custom_brand_email || "support@beyondpresence.ai"
    }
    return "support@beyondpresence.ai"
  }, [agent])

  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Box
        sx={{
          flex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          px: { xs: 2, sm: 3, md: 4 },
          py: { xs: 2, sm: 2 },
          zIndex: 1,
          overflow: "hidden",
        }}
      >
        <Stack
          direction={"column"}
          spacing={isMobile ? 1.5 : isTablet ? 2.5 : 3.5}
          sx={{
            width: "100%",
            maxWidth: "600px",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            position: "relative",
            zIndex: 1,
          }}
        >
          {error && (
            <Box
              sx={{
                width: "100%",
                maxWidth: { xs: "200px", sm: "250px", md: "300px" },
                mb: { xs: 1, sm: 2 },
              }}
            >
              <img
                src={AgentNotFoundImage}
                alt="Agent not found"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          )}
          <Typography
            text={error ? "Something went wrong!" : headline}
            size={TypographySize.HeadingXL}
          />
          {error && (
            <Stack direction={"column"} spacing={1}>
              <Typography
                text="Please contact support if this issue persists."
                size={TypographySize.TextM}
                weight={TypographyWeight.Regular}
              />
              <a href={`mailto:${emailAddress}`}>
                <Typography
                  text={emailAddress}
                  size={TypographySize.TextM}
                  weight={TypographyWeight.Regular}
                />
              </a>
            </Stack>
          )}
          {!error && (
            <Fragment>
              <Stack
                direction={"column"}
                spacing={1.5}
                sx={{
                  width: "100%",
                  maxWidth: { xs: "100%", sm: "250px" },
                }}
              >
                <MainButton
                  onClick={rejoin}
                  disabled={buttonsDisabled || !call}
                  size={isMobile ? ButtonSize.sm : ButtonSize.md}
                  text="Start a new call"
                />
                {hasCustomButton ? (
                  <MainButton
                    onClick={handleCustomButtonClick}
                    disabled={buttonsDisabled}
                    size={isMobile ? ButtonSize.sm : ButtonSize.md}
                    text={agent?.custom_brand_button_text || ""}
                    hierarchy={ButtonHierarchy.SecondaryColor}
                  />
                ) : (
                  <MainButton
                    onClick={handleEndCallAndSummary}
                    disabled={buttonsDisabled}
                    size={isMobile ? ButtonSize.sm : ButtonSize.md}
                    text={
                      !agent?.custom_branding
                        ? "End call and send me summary!"
                        : "End call!"
                    }
                    hierarchy={ButtonHierarchy.Tetriary}
                  />
                )}
              </Stack>
            </Fragment>
          )}
          <SnackbarWithText
            open={summaryOpen}
            severity={SnackbarType.success}
            text={`A summary of the meeting was sent to ${email}`}
            handleClose={handleClose}
          />
          {!error && (
            <Box sx={{ mt: { xs: 1, sm: 2, md: 3 } }}>
              {feedbackSubmitted ? (
                <FeedbackSubmitted />
              ) : (
                <Feedback
                  visible={feedbackOpen}
                  onClose={() => setFeedbackOpen(false)}
                  onOpen={() => setFeedbackOpen(true)}
                  onSubmit={() => setFeedbackSubmitted(true)}
                />
              )}
            </Box>
          )}
        </Stack>
      </Box>
      <Background />
    </Box>
  )
}
