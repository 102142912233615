import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles"
import CssBaseline from "@mui/material/CssBaseline"
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
} from "@mui/material/styles"
import * as Sentry from "@sentry/react"
import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import mixpanel from "mixpanel-browser"
import React, { useCallback, useEffect, useState } from "react"
import { createBrowserRouter, Params, RouterProvider } from "react-router-dom"
import { Loader } from "../components/loader"
import { initAuthObserver, initializeAuth } from "../external/auth"
import { RoomLoader } from "../types/Call"
import { StateAction } from "../types/State"
import { isDev, isLocalDev, isStaging } from "../utils/general"
import { appReducer, initialState } from "./AppContext"
import { AppContext, AppDispatchContext } from "./Context"
import { usePersistedReducer } from "./persistedReducer"
import { getRouterData } from "./routes/router"

if (!isLocalDev) {
  mixpanel.init(
    isDev
      ? "cd6d221954f1477b41cac2c257910ab7"
      : isStaging
        ? "aed2ff230af1d514b983de7680ee30b0"
        : "06c39334f3ed58bee551506171e0ef81",
    {
      debug: false,
      track_pageview: true,
      persistence: "localStorage",
    },
  )
}

const firebaseConfig = isDev
  ? {
      apiKey: "AIzaSyC788lZ2gkp8HjPNDhpWNBHsLCVUGaOksY",
      authDomain: isLocalDev ? "bey-dev.firebaseapp.com" : "dev.bey.chat",
      projectId: "bey-dev",
      storageBucket: "bey-dev.appspot.com",
      messagingSenderId: "347029147333",
      appId: "1:347029147333:web:8c9c85b285179c0be4eaac",
      measurementId: "G-NBGKVM0BES",
    }
  : isStaging
    ? {
        apiKey: "AIzaSyC9TAzKVcWIruehblFd-4gI7MpPyFM2I7Q",
        authDomain: "staging.bey.chat",
        projectId: "bey-staging",
        storageBucket: "bey-staging.firebasestorage.app",
        messagingSenderId: "418945392850",
        appId: "1:418945392850:web:5e7c4d06e9ffaf5bbb8d0b",
        measurementId: "G-2CPNB48FMS",
      }
    : {
        apiKey: "AIzaSyCx2GDcIjQQ9nTtIbHHf6wGwvnp_2V7jX4",
        authDomain: "bey.chat",
        projectId: "bey-prod",
        storageBucket: "bey-prod.appspot.com",
        messagingSenderId: "352012720018",
        appId: "1:352012720018:web:27a46e753a2e56983f534b",
        measurementId: "G-BN8E3VD31V",
      }

const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const db = getFirestore(app)
auth.useDeviceLanguage()

const theme = materialExtendTheme({
  typography: {
    fontFamily: "var(--app-font-family, figtree, sans-serif)",
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 600,
  },
})

function App() {
  const { state, dispatch } = usePersistedReducer(
    appReducer,
    initialState,
    "state",
  )
  const [loading, setLoading] = useState(true)
  const [authInitialized, setAuthInitialized] = useState(false)

  // Add custom font family if specified in agent branding
  useEffect(() => {
    if (state.agent?.custom_branding) {
      if (state.agent?.custom_font_family) {
        // Create link element for Google Fonts
        const link = document.createElement("link")
        link.href = state.agent.custom_font_url
        link.rel = "stylesheet"
        document.head.appendChild(link)

        // Set the CSS variable
        document.documentElement.style.setProperty(
          "--app-font-family",
          state.agent.custom_font_family,
        )
      }
      if (state.agent?.custom_brand_color) {
        document.documentElement.style.setProperty(
          "--colors-light-background-bg-button-disabled",
          state.agent.custom_brand_color + " !important",
        )
        // Add a direct style override for higher specificity
        const styleSheet = document.createElement("style")
        styleSheet.textContent = `
          :root {
            --colors-light-background-bg-brand: ${state.agent.custom_brand_color} !important;
            --colors-light-background-bg-brand-hover: ${state.agent.custom_brand_color.slice(0, -2)}bf !important;
          }
          .MuiButton-disabled {
            background-color: ${state.agent.custom_brand_color} !important;
          }
        `
        document.head.appendChild(styleSheet)
      }

      // Update document title if custom title is provided
      if (state.agent?.custom_title) {
        document.title = state.agent.custom_title
      }

      // Update favicon if custom favicon URL is provided
      if (state.agent?.custom_favicon_url) {
        const faviconLink = document.querySelector(
          "link[rel='icon']",
        ) as HTMLLinkElement
        if (faviconLink) {
          faviconLink.href = state.agent.custom_favicon_url
        } else {
          const newFaviconLink = document.createElement("link")
          newFaviconLink.rel = "icon"
          newFaviconLink.type = "image/svg+xml"
          newFaviconLink.href = state.agent.custom_favicon_url
          document.head.appendChild(newFaviconLink)
        }
      }
    } else {
      document.documentElement.style.setProperty(
        "--app-font-family",
        "figtree, sans-serif",
      )
      // Reset title and favicon to defaults when custom branding is disabled
      document.title = "Beyond Presence - Call"
      const faviconLink = document.querySelector(
        "link[rel='icon']",
      ) as HTMLLinkElement
      if (faviconLink) {
        faviconLink.href = "./src/assets/icon.svg"
      }
    }
  }, [
    state.agent?.custom_branding,
    state.agent?.custom_font_family,
    state.agent?.custom_brand_color,
    state.agent?.custom_title,
    state.agent?.custom_favicon_url,
  ])

  const roomLoader = useCallback(
    ({ params }: { params: Params }): RoomLoader => ({
      matchedElement: state.call,
    }),
    [state.call],
  )

  const router = createBrowserRouter(getRouterData(state, roomLoader))

  useEffect(() => {
    const initialize = async () => {
      setLoading(true)
      try {
        const user = await initializeAuth()
        if (user) {
          setAuthInitialized(true)
        }
      } catch (error) {
        console.error("Failed to initialize auth:", error)
        Sentry.captureException(error)
        setAuthInitialized(true)
      }
      setLoading(false)
    }
    initialize()
  }, [state?.agent.custom_branding])

  useEffect(() => {
    const unsubscribe = initAuthObserver((user) => {
      if (user) {
        dispatch({
          type: StateAction.setUser,
          payload: {
            uid: user.uid,
            name: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
          },
        })
        if (!isLocalDev) {
          mixpanel.identify(user.uid)
          mixpanel.people.set({ $name: user.displayName, $email: user.email })
        }
        setAuthInitialized(true)
      } else {
        dispatch({ type: StateAction.resetState })
        if (!isLocalDev) {
          mixpanel.reset()
        }
      }
    })

    return () => unsubscribe()
  }, [])

  if (loading && !authInitialized) {
    return <Loader />
  }

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: theme }}>
          <JoyCssVarsProvider>
            <CssBaseline enableColorScheme />
            <RouterProvider router={router} />
          </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  )
}

export default App
