import Flags from "country-flag-icons/react/3x2"
import { getDefaultAgentId, getDefaultCreatorId } from "../utils/general"

export enum AgentLanguages {
  Bulgarian = "bg",
  Chinese = "zh",
  Czech = "cs",
  Danish = "da",
  Dutch = "nl",
  English = "en",
  EnglishAustralia = "en-AU",
  EnglishCanada = "en-CA",
  EnglishUnitedKingdom = "en-GB",
  Finnish = "fi",
  French = "fr",
  FrenchCanada = "fr-CA",
  German = "de",
  Greek = "el",
  Hindi = "hi",
  Indonesian = "id",
  Italian = "it",
  Japanese = "ja",
  Korean = "ko",
  Malay = "ms",
  Polish = "pl",
  Portuguese = "pt",
  PortugueseBrazil = "pt-BR",
  PortuguesePortugal = "pt-PT",
  Romanian = "ro",
  Russian = "ru",
  Slovak = "sk",
  Spanish = "es",
  Swedish = "sv",
  Turkish = "tr",
  Ukrainian = "uk",
}

export const CountryFlags: Record<AgentLanguages, Flags.FlagComponent> = {
  [AgentLanguages.Bulgarian]: Flags.BG,
  [AgentLanguages.Chinese]: Flags.CN,
  [AgentLanguages.Czech]: Flags.CZ,
  [AgentLanguages.Danish]: Flags.DK,
  [AgentLanguages.Dutch]: Flags.NL,
  [AgentLanguages.English]: Flags.US,
  [AgentLanguages.EnglishAustralia]: Flags.AU,
  [AgentLanguages.EnglishCanada]: Flags.CA,
  [AgentLanguages.EnglishUnitedKingdom]: Flags.GB,
  [AgentLanguages.Finnish]: Flags.FI,
  [AgentLanguages.French]: Flags.FR,
  [AgentLanguages.FrenchCanada]: Flags.CA,
  [AgentLanguages.German]: Flags.DE,
  [AgentLanguages.Greek]: Flags.GR,
  [AgentLanguages.Hindi]: Flags.IN,
  [AgentLanguages.Indonesian]: Flags.ID,
  [AgentLanguages.Italian]: Flags.IT,
  [AgentLanguages.Japanese]: Flags.JP,
  [AgentLanguages.Korean]: Flags.KR,
  [AgentLanguages.Malay]: Flags.MY,
  [AgentLanguages.Polish]: Flags.PL,
  [AgentLanguages.Portuguese]: Flags.PT,
  [AgentLanguages.PortugueseBrazil]: Flags.BR,
  [AgentLanguages.PortuguesePortugal]: Flags.PT,
  [AgentLanguages.Romanian]: Flags.RO,
  [AgentLanguages.Russian]: Flags.RU,
  [AgentLanguages.Slovak]: Flags.SK,
  [AgentLanguages.Spanish]: Flags.ES,
  [AgentLanguages.Swedish]: Flags.SE,
  [AgentLanguages.Turkish]: Flags.TR,
  [AgentLanguages.Ukrainian]: Flags.UA,
}

export const initialAgent = {
  id: getDefaultAgentId,
  createdAt: new Date(),
  updatedAt: new Date(),
  name: "Demo Agent",
  creatorId: getDefaultCreatorId,
  language: AgentLanguages.English,
}

export interface Agent {
  id: string
  createdAt: Date
  updatedAt: Date
  creatorId?: string
  state?: string
  modalCallId?: string
  billingEventId?: string
  name?: string
  description?: string
  mode?: string
  greeting?: string
  avatarId?: string
  knowledgeIds?: string[]
  active?: boolean
  max_session_length_minutes?: number
  number_of_sessions?: number
  notification_threshold_sessions?: number
  language: AgentLanguages | null
  custom_branding?: boolean
  custom_allow_bey_branding?: boolean | true
  custom_logo_url?: string | null
  custom_background_url?: string | null
  custom_brand_color?: string | null
  custom_accent_color?: string | null
  custom_font_color?: string | null
  custom_font_family?: string | null
  custom_brand_website?: string | null
  custom_brand_email?: string | null
  custom_font_url?: string | null
  custom_title?: string | null
  custom_favicon_url?: string | null
  custom_brand_button_text?: string | null
  custom_brand_button_url?: string | null
}
