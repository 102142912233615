import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import { Colors } from "../../constants/colors"
import { formatTime } from "../../utils/func"

export interface CustomCallTimerProps {
  duration: number
  maxDuration: number
  chatEnabled: boolean
}

export default function CustomCallTimer({
  duration,
  maxDuration,
  chatEnabled,
}: CustomCallTimerProps) {
  const timeRemaining = Math.max(0, maxDuration - duration)
  const formattedTimeRemaining = formatTime(timeRemaining)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const rightPosition = React.useMemo(() => {
    if (isMobile) {
      return "2rem"
    }
    // Position relative to the container width and chat state
    const containerWidth = window.innerWidth * 0.7
    const rightMargin = !chatEnabled
      ? (window.innerWidth - containerWidth) / 2
      : window.innerWidth * 0.3
    return `${rightMargin + 32}px` // 32px = 2rem
  }, [isMobile, chatEnabled])

  return (
    <Box
      padding={"6px 12px"}
      position="fixed"
      top={"4.75rem"}
      right={rightPosition}
      color={Colors.White}
      sx={{ backgroundColor: "#59514B", backdropFilter: "blur(3px)" }}
      borderRadius={"50px"}
      zIndex={1}
    >
      <Typography color={Colors.White} variant="body2">
        Remaining time: {formattedTimeRemaining}
      </Typography>
    </Box>
  )
}
