import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { AccountMobile } from "../../components/accountMobile"
import AgentNameHeader from "../../components/agentNameHeader"
import Background from "../../components/background"
import { Header } from "../../components/header"
import { SnackbarWithText } from "../../components/snackbar"
import { logAnalyticsEvent } from "../../external/analytics"
import { signUserOut } from "../../external/auth"
import { getAgent, verifyAgentExists } from "../../external/firestore"
import { initialAgent } from "../../types/Agent"
import { AnalyticsEvents } from "../../types/Analytics"
import { StateAction } from "../../types/State"
import { SnackbarType } from "../../types/UI"
import { getAvailableCredits, isAnonymous } from "../../utils/func"
import {
  getAgentUsage,
  getCapacity,
  getDefaultAgentId,
  getDefaultCreatorId,
} from "../../utils/general"
import { useAppContext, useAppDispatchContext } from "../Context"
import AgentNotFound from "./AgentNotFound"
import { MediaSetup } from "./MediaSetup"
import { UserInput } from "./UserInput"

const sendAnalyticsEvent = () => {
  logAnalyticsEvent(AnalyticsEvents.AgentVisited)
}

export function PreScreen() {
  const [permissionsGranted, setPermissionsGranted] = useState(false)
  const [capacity, setCapacity] = useState<boolean>(false)
  const [overUsage, setOverUsage] = useState<boolean>(false)
  const [agentError, setAgentError] = useState<string>("")
  const [agentExists, setAgentExists] = useState<boolean>(true)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const location = useLocation()
  const { state } = location
  const { user, agent, stream } = useAppContext()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatchContext()

  const isUserAnonymous = isAnonymous()

  useEffect(() => {
    setPermissionsGranted(false)
    if (stream) {
      dispatch({ type: StateAction.setStream, payload: null })
    }
  }, [location.pathname, location.search])

  useEffect(() => {
    const req = async () => {
      if (isUserAnonymous) {
        await signUserOut()
        dispatch({ type: StateAction.setUser, payload: null })
      }
    }
    req()
  }, [user])

  useEffect(() => {
    if (state && user) {
      navigate(`/${state}`)
    }
  }, [state, user])

  useEffect(() => {
    sendAnalyticsEvent()
  }, [sendAnalyticsEvent])

  useEffect(() => {
    const req = async () => {
      try {
        if (user && !isUserAnonymous) {
          const res = await getCapacity()
          setCapacity(res)
        }
      } catch (error) {
        console.error("error fetching capacity", error)
      }
    }
    req()
  }, [user])

  useEffect(() => {
    const req = async () => {
      try {
        const agentId = id || getDefaultAgentId
        if (agentId === getDefaultAgentId || !user) {
          setAgentExists(true)
          dispatch({ type: StateAction.setAgent, payload: initialAgent })
          dispatch({ type: StateAction.setAgentId, payload: agentId })
          dispatch({ type: StateAction.setAgentUsage, payload: null })
          return
        }

        const exists = await verifyAgentExists(agentId)
        setAgentExists(exists)
        if (!exists) {
          setAgentError(`Agent with ID ${agentId} does not exist`)
          return
        }

        const [usage, fetchedAgent] = await Promise.all([
          getAgentUsage(agentId),
          getAgent(agentId),
        ])

        if (fetchedAgent.creatorId === getDefaultCreatorId) {
          setAgentExists(true)
          dispatch({ type: StateAction.setAgent, payload: initialAgent })
          dispatch({ type: StateAction.setAgentId, payload: agentId })
          dispatch({ type: StateAction.setAgentUsage, payload: null })
          return
        }

        dispatch({ type: StateAction.setAgent, payload: fetchedAgent })

        if (usage.pay_as_you_go_enabled) {
          dispatch({
            type: StateAction.setAgentUsage,
            payload: {
              pay_as_you_go_enabled: usage.pay_as_you_go_enabled,
              current_usage: usage.current_usage,
              usage_limit: null,
              subscription_plan: usage.subscription_plan,
            },
          })
          return
        } else {
          dispatch({ type: StateAction.setAgentUsage, payload: usage })
          const remainingCredits = getAvailableCredits(usage)
          setOverUsage(remainingCredits <= 0)
        }
      } catch (error) {
        console.error("error fetching usage", error)
        setAgentError("Error checking agent availability")
      }
    }
    req()
  }, [id])

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{
        position: "relative",
        minHeight: "100vh",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Header />
      <SnackbarWithText
        open={agentError !== ""}
        severity={SnackbarType.error}
        text={agentError}
        handleClose={() => setAgentError("")}
      />
      <Stack
        direction={"column"}
        spacing={2}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
          px: { xs: 1, sm: 2, md: 2, lg: 3, xl: 4 },
          overflow: "hidden",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Grid
          container
          spacing={isMobile ? 1 : 3}
          justifyContent="center"
          alignItems="center"
          sx={{
            p: { xs: 1, sm: 2, md: 2, lg: 3 },
            width: "100%",
            m: 0,
            maxWidth: "1440px",
            position: "relative",
            zIndex: 1,
          }}
        >
          <Grid
            item
            md={1}
            xl={2}
            display={{ xs: "none", md: "block" }}
            sx={{ maxWidth: { md: "4%", lg: "8.33%" } }}
          />
          <Grid
            item
            xs={12}
            md={6}
            xl={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              maxWidth: { xs: "100%", sm: "600px", md: "58%", lg: "50%" },
              mx: "auto",
              px: { xs: 0, sm: 1, md: 1, lg: 2 },
              gap: 1,
              position: "relative",
              zIndex: 1,
            }}
          >
            <AgentNameHeader />
            <MediaSetup
              setPermissionsGranted={setPermissionsGranted}
              disableButton={capacity || overUsage || !agentExists || !user}
            />
          </Grid>
          <Grid
            xs={12}
            item
            md={4}
            xl={3}
            sx={{
              width: "100%",
              maxWidth: {
                xs: "100%",
                sm: "600px",
                md: "38%",
                lg: "33.333%",
              },
              mx: "auto",
              px: { xs: 0, sm: 1, md: 1, lg: 2 },
              mt: { xs: 2, md: 0 },
              position: "relative",
              zIndex: 1,
            }}
          >
            {agentExists ? (
              <UserInput
                permissionsGranted={permissionsGranted}
                capacity={capacity}
                overUsage={overUsage}
              />
            ) : (
              <AgentNotFound />
            )}
          </Grid>
          <Grid
            item
            md={1}
            xl={2}
            display={{ xs: "none", md: "block" }}
            sx={{ maxWidth: { md: "4%", lg: "8.33%" } }}
          />
        </Grid>
        {isMobile ? <AccountMobile /> : null}
      </Stack>
      <Background />
    </Stack>
  )
}
