import { Avatar, Box, Button, Stack, Typography } from "@mui/material"
import React from "react"
import { useAppContext } from "../app/Context"
import { signUserOut } from "../external/auth"
import { isAnonymous } from "../utils/func"
export function AccountMobile() {
  const context = useAppContext()
  const user = context.user

  const isUserAnonymous = isAnonymous()

  return (
    <>
      {user != null && !isUserAnonymous ? (
        <Box sx={{ dislay: "flex", alignItems: "center", flexGrow: 0 }}>
          <Stack direction="row" spacing={2}>
            <Avatar>{user.email?.[0]}</Avatar>
            <Typography variant="body2" sx={{ pt: "9px" }}>
              {user.email}
            </Typography>
            <Button
              variant="text"
              onClick={signUserOut}
              sx={{ textTransform: "none", color: "blue" }}
            >
              <Typography variant="body2">(Switch)</Typography>
            </Button>
          </Stack>
        </Box>
      ) : null}
    </>
  )
}
