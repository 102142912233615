import "@beydesign/tokens/build/css/variables.css"
import * as Sentry from "@sentry/react"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./app/App"
import "./index.css"
import { isDev, isLocalDev, isStaging } from "./utils/general"

if (!isLocalDev) {
  Sentry.init({
    dsn: "https://79ef75a1ca8c8b89b2348537b2086ea6@o4507310462140416.ingest.de.sentry.io/4507310468169808",
    environment: isDev ? "development" : isStaging ? "staging" : "production",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.01, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/bey\.chat/,
      /^https:\/\/api\.bey\.chat/,
      /^https:\/\/backend\.bey\.chat/,
      /^https:\/\/backend-dev\.bey\.chat/,
      /^https:\/\/dev\.bey\.chat/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
