import { Logo, LogoVariant } from "@beydesign/storybook"
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import { useAppContext } from "../../app/Context"
import {
  Colors,
  isBeyBrandingEnabled,
  isCustomBrandingEnabled,
} from "../../constants/colors"

interface AppLogoProps {
  chatEnabled?: boolean
  isLoading?: boolean
}

export const AppLogo: React.FC<AppLogoProps> = ({
  chatEnabled,
  isLoading = false,
}) => {
  const agent = useAppContext()?.agent
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const leftPosition = React.useMemo(() => {
    if (isMobile) {
      return "2.25rem"
    }
    // Position relative to the container width and chat state
    const containerWidth = window.innerWidth * 0.7
    const leftMargin = !chatEnabled
      ? (window.innerWidth - containerWidth) / 2
      : 0
    return `${leftMargin + 36}px` // 36px = 2.25rem
  }, [isMobile, chatEnabled])

  const textColor = isLoading ? Colors.Purple : Colors.Grey
  const logoColor = isLoading ? Colors.Purple : Colors.Grey

  return (
    <Box
      position="fixed"
      top={"4.75rem"}
      left={leftPosition}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={1}
    >
      {isBeyBrandingEnabled() && (
        <Typography variant="body2" color={textColor} sx={{ opacity: 0.8 }}>
          powered by
        </Typography>
      )}
      {(!isCustomBrandingEnabled() || isBeyBrandingEnabled()) && (
        <Logo variant={LogoVariant.Icon} color={logoColor} />
      )}
    </Box>
  )
}
